import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getTrashList() {
    const url = `/trash`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getTrash(trashId) {
    const url = `/trash/${trashId}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async trashRecovery(data, trashId) {
    const url = `/trash/recovery?trashId=${trashId}`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteTrash(trashId) {
    const url = `/trash?trashId=${trashId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteAllTrash() {
    const url = `/trash/all`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}