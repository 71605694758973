import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getUserList(page, searchType, searchWord, beginDate, endDate) {
    const url = `/admin/user-list?page=${page}&searchType=${searchType}&searchWord=${searchWord}&beginDate=${beginDate}&endDate=${endDate}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getActiveUserCount() {
    const url = '/admin/active-user-count';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },

  async getBoardDataList(page, beginDate, endDate) {
    const url = `/board-manage?page=${page}&beginDate=${beginDate}&endDate=${endDate}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getSearchBoardDataList(searchType, searchWord, page, beginDate, endDate) {
    const url = `/board-manage/search?searchType=${searchType}&searchWord=${searchWord}&page=${page}&beginDate=${beginDate}&endDate=${endDate}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getTagBoardDataList(page, tag, beginDate, endDate) {
    const url = `/board-manage/tag?page=${page}&tag=${tag}&beginDate=${beginDate}&endDate=${endDate}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateCheckCodeBookItems(data, codebookIds) {
    const url = `/board-manage/codebook?codebookIds=${codebookIds}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteBoardItems(boardIds) {
    const url = `/board-manage/check-item?boardIds=${boardIds}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },


  async getLogList(page, searchWord, beginDate, endDate) {
    const url = `/admin/log?page=${page}&searchWord=${searchWord}&beginDate=${beginDate}&endDate=${endDate}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteLog(logId) {
    const url = `/admin/log?logId=${logId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}