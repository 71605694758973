import customAxios from '@/api/customAxios';

export default {
  async migrationMemo(data) {
    const url = '/migration/memo';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
  async migrationTrash(data) {
    const url = '/migration/trash';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
  async migrationMaintenance(data) {
    const url = '/migration/maintenance';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
  async migrationCodebook(data) {
    const url = '/migration/codebook';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
  async migrationSchedule(data) {
    const url = '/migration/schedule';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
}