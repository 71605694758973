import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getInquiries(page) {
    const url = `/inquiry?page=${page}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async addInquiry(data) {
    const url = `/inquiry`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateInquiry(data, inquiryId) {
    const url = `/inquiry?inquiryId=${inquiryId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteInquiry(inquiryId) {
    const url = `/inquiry?inquiryId=${inquiryId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}