import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async registerPasskey(data) {
    const url = '/webAuthn/register-passkey';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async registerVerifyPasskey(data) {
    const url = '/webAuthn/verify-passkey';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async authenticatePasskey(data) {
    const url = '/webAuthn/authenticate-passkey';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async verifyAuthenticatePasskey(data) {
    const url = '/webAuthn/verify-authenticate-passkey';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deletePasskey() {
    const url = '/webAuthn';
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}