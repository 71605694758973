import router from './router'
import Store from './store'
router.beforeEach((to, from, next) => {
  if (to.path === '/login') { // login path
    next();
  } else if (to.path === '/forget-pw') {
    next();
  } else if (to.path === '/signup') {
    next();
  } else {
    if (Store.state.userData.user === null) {
      next('/login');
    } else {
      next();
    }
  }
});