import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getPlaceList() {
    const url = `/place`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getPlaceHomeAndCompany() {
    const url = `/place/homeAndCompany`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getAlwaysShowPlaceList() {
    const url = `/place/alwaysShow`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async createPlace(data) {
    const url = `/place`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updatePlace(data, placeId) {
    const url = `/place?placeId=${placeId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateAlwaysPlace(data, placeId) {
    const url = `/place/always?placeId=${placeId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deletePlace(placeId) {
    const url = `/place?placeId=${placeId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}