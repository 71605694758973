import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getCarData() {
    const url = '/car';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async addCarData(data) {
    const url = '/car';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token, 'Content-Type': 'multipart/form-data'}
    })
    return Promise.resolve(response.data);
  },
  async updateCarData(data) {
    const url = '/car';
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token, 'Content-Type': 'multipart/form-data'}
    })
    return Promise.resolve(response.data);
  },
  async updateCarMileageStandard(data) {
    const url = '/car/mileageStandard';
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },

  async getCatMaintenanceHistoryList(page, pageSize) {
    const url = `/car/maintenance?page=${page}&pageSize=${pageSize}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getSearchCatMaintenanceHistoryList(page, pageSize, searchWord) {
    const url = `/car/maintenance/search?page=${page}&pageSize=${pageSize}&searchWord=${searchWord}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async createCarMaintenanceHistory(data) {
    const url = '/car/maintenance';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token, 'Content-Type': 'multipart/form-data'}
    })
    return Promise.resolve(response.data);
  },
  async updateCarMaintenanceHistory(data) {
    const url = '/car/maintenance';
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token, 'Content-Type': 'multipart/form-data'}
    })
    return Promise.resolve(response.data);
  },
  async deleteCatMaintenanceHistory(maintenanceId, fileName) {
    const fileNameStr = fileName === null ? '' : `&fileName=${fileName}`
    const url = `/car/maintenance?maintenanceId=${maintenanceId}${fileNameStr}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}