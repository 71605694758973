import Vue from 'vue'
import { isMobile } from 'mobile-device-detect';
import router from '@/router/index';
import Store from '@/store/index';
import { Set_routes } from '@/router';
import { pushRoute, replaceRoute } from '@/router/navigate';
import dayjs from "dayjs";
import 'dayjs/locale/ko';
import {DEFAULT_COLOR} from '@/constants';

let comMixin = {
	methods: {
		mobileCheck() {
			let check;
			check = isMobile;
			return check;
		},
		routerBack() {
			replaceRoute(this.$routerHistory.previous().path, {});
		},
		goHome() {
			replaceRoute('/home', {});
		},
		goNotification() {
			pushRoute('/home/notification', {});
		},
		goMemo() {
			replaceRoute('/memo', {});
		},
		goMemoStorage() {
			replaceRoute('/memo/storage', {});
		},
		goTrash() {
			replaceRoute('/trash', {});
		},
		goCalendar() {
			replaceRoute('/calendar', {});
		},
		goMap() {
			replaceRoute('/map', {});
		},
		goMyInfo() {
			replaceRoute('/myinfo', {});
		},
		goCodeBook() {
			Store.commit('setSelectedCodeTag', undefined);
			Store.commit('setTempCodebookData', undefined);
			Store.commit('setTempCodebookTag', undefined);
			Store.commit('setCodebookDataList', []);
			replaceRoute('/codebook', {});
		},
		goBoard() {
			Store.commit('setSelectedBoardTag', undefined);
			Store.commit('setTempBoardData', undefined);
			Store.commit('setTempBoardTag', undefined);
			replaceRoute('/board', {});
		},
		goCar() {
			replaceRoute('/car', {});
		},
		goParking() {
			const parkingData = Store.getters.getParkingData;
			if(parkingData == null) {
				replaceRoute('/car/parking/parkinglot', {});
			} else {
				if(Store.state.user.email === parkingData.email) replaceRoute('/car/parking', {});
				else replaceRoute('/car/parking/parkinglot', {});
			}
		},
		goSchedule() {
			replaceRoute('/schedule', {});
		},
		goSetting() {
			replaceRoute('/setting', {});
		},
		goAdmin() {
			replaceRoute('/admin', {});
		},
		getUser() {
			return Store.state.userData.user;
		},
		getToken() {
			return Store.state.userData.user.token;
		},
		getUserName() {
			return Store.state.userData.user.userName;
		},
		getUserImageUrl() {
			return Store.state.userData.user.imageUrl;
		},
		pushRoute(url, query) {
			let urlQ = '';
			for (let item in query) {
				urlQ += '&' + item + '=' + query[item];
			}
			urlQ = urlQ.replace(/\&/, '?');
			router.push(url + urlQ).catch(()=> {});
		},
		replaceRoute(url, query) {
			let urlQ = '';
			for (let item in query) {
				urlQ += '&' + item + '=' + query[item];
			}
			urlQ = urlQ.replace(/\&/, '?');
			router.replace(url + urlQ).catch(()=> {});
		},
		getRouteTitle(path) {
			let result;
			Set_routes.forEach((route) => {
				if(route.path == path) {
					result = route.title;
				}
			});
			return result;
		},
		messageBox(text, type) {
			if(type === 'default') {
				this.$dialog.message.info(text, {
					position: 'top', timeout: 2000, color: DEFAULT_COLOR
				});
			} else if(type === 'success') {
				this.$dialog.message.success(text, {
					position: 'top', timeout: 2000
				});
			} else if(type === 'warning') {
				this.$dialog.message.warning(text, {
					position: 'top', timeout: 2000
				});
			} else if(type === 'error') {
				this.$dialog.message.error(text, {
					position: 'top', timeout: 2000
				});
			} else { // info
				this.$dialog.message.info(text, {
					position: 'top', timeout: 2000
				});
			}
		},
		pageCalculator(pageSize, total) {
			// pagesize와 data total값으로 페이지수를 계산해준다. ceil은 소수점 올림
			if(total / pageSize < 0) {
				return Math.ceil(total / pageSize);
			} else if (total / pageSize === 0) {
				return 1;
			} else {
				return Math.ceil(total / pageSize);
			}
		},
		validateEmail(email) {
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(email).toLowerCase());
		},
		fromSecondToHHMMSS(secondValue) {
			let hoursStr, minutesStr, secondsStr;
			let hours   = Math.floor(secondValue / 3600);
			let minutes = Math.floor((secondValue - (hours * 3600)) / 60);
			let seconds = secondValue - (hours * 3600) - (minutes * 60);

			if (hours < 10) { hoursStr = "0" + hours; }
			if (hours >= 10) { hoursStr = hours; }
			if (minutes < 10) { minutesStr = "0" + minutes; }
			if (minutes >= 10) { minutesStr = minutes; }
			if (seconds < 10) { secondsStr = "0" + seconds; }
			if (seconds >= 10) { secondsStr = seconds; }
			return hoursStr + ':' + minutesStr + ':' + secondsStr.toString().substr(0,2);
		},
		getFormatDate(date) {
			return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
		},
		formatDateKor(date) {
			return dayjs(date).format('YYYY년 MM월 DD일 HH:mm:ss');
		},
		enterCheck(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g,"</br>");
    },
		getDateDiff(date) { // 반환값 => 일(Day)
			const date1 = new Date();
			const date2 = new Date(date);
			const diffDate = date1.getTime() - date2.getTime();
			return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
		},
		getDateDiffDayjs(date) { // 반환값 => 일(Day)
			const date1 = dayjs()
			const date2 = dayjs(date)
			return date1.diff(date2, 'day');
		},
		findRecentEngineoil(list) {
			let recentTime = 0;
			let recentOilEvent = undefined;
			list.forEach((item) => {
				if(item.maintenanceContent.includes('엔진오일')) {
					if(recentTime < item.timeMillisecond) {
						recentTime = item.timeMillisecond;
						recentOilEvent = item;
					}
				}
			})
			return recentOilEvent;
		},
		fineRecentbulls(list) {
			let recentTime = 0;
			let recentbullsEvent = undefined;
			list.forEach((item) => {
				if(item.maintenanceContent.includes('불스원샷')) {
					if(recentTime < item.timeMillisecond) {
						recentTime = item.timeMillisecond;
						recentbullsEvent = item;
					}
				}
			})
			return recentbullsEvent
		},
		checkStringOnlyBlankPattern(str) {
			// 공백만 입력한 경우 체크
			const blank_pattern = /^\s+$/g;
			return blank_pattern.test(str); // true, false로 반환
		},
		checkStringBlankPattern(str) {
			// 문자열에 공백이 있는 경우
			const blank_pattern = /[\s]/g;
			return blank_pattern.test(str);  // true, false로 반환
		},
		checkStringSpecialPattern(str) {
			// 특수문자가 있는 경우 체크
			const special_pattern = /[`~!@#$%^&*|\\\'\";:\/?]/gi;
			return special_pattern.test(str);  // true, false로 반환
		},
		checkStringBlankWithSpecialPattern(str) {
			// 문자열에 공백 또는 특수문자가 같이 있는 경우
			if(str.search(/\W|\s/g) > -1) {
				return true;
			} else {
				return false;
			}
		},
		checkNumberAndString(str) {
			// 문자와 숫자가 아닌경우
			const regex = /[^a-zA-Z0-9가-힣ㄱ-ㅎ]/g;
			return regex.test(str);  // true, false로 반환
		},
		degStr(deg) {
			const value = Number(deg)
			if(value === 0) return '북'
			else if(value > 0 && value < 45) return '북북동'
			else if(value === 45) return '북동'
			else if(value > 45 && value < 90) return '동북동'
			else if(value === 90) return '동'
			else if(value > 90 && value < 135) return '동남동'
			else if(value === 135) return '남동'
			else if(value > 135 && value < 180) return '남남동'
			else if(value === 180) return '남'
			else if(value > 180 && value < 225) return '남남서'
			else if(value === 225) return '남서'
			else if(value > 225 && value < 270) return '서남서'
			else if(value === 270) return '서'
			else if(value > 270 && value < 315) return '서북서'
			else if(value === 315) return '북서'
			else if(value > 315 && value < 360) return '북북서'
		}
	}
}
export default comMixin;