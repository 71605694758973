import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getCodebookList(page) {
    const url = `/codebook?page=${page}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getTagCodebookList(page, tag) {
    const url = `/codebook/tag?page=${page}&tag=${tag}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getSearchCodebookList(page, searchWord) {
    const url = `/codebook/search?page=${page}&searchWord=${searchWord}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getCodebookData(codebookId) {
    const url = `/codebook/${codebookId}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async createCodebookData(data) {
    const url = `/codebook`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateCodebookData(data, codebookId) {
    const url = `/codebook?codebookId=${codebookId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateCodebookBoardInfo(data, codebookId) {
    const url = `/codebook/board?codebookId=${codebookId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteCodebookData(codebookId) {
    const url = `/codebook?codebookId=${codebookId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}