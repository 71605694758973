import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getUserIdList() {
    const url = '/user/userId-list';
    const response = await customAxios({
      url, method: 'GET',  headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getUserData() {
    const url = '/user';
    const response = await customAxios({
      url, method: 'GET',  headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateLoginTime(data) {
    const url = '/user/login-time';
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateMyInfo(data) {
    const url = '/user';
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token, 'Content-Type': 'multipart/form-data'}
    })
    return Promise.resolve(response.data);
  },
  async updateMyImage(data) {
    const url = '/fileSystem/upload/avatar-image';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token, 'Content-Type': 'multipart/form-data'}
    })
    return Promise.resolve(response.data);
  },
}