import router from './index';
export function pushRoute(url, query) {
  let urlQ = '';
  for (let item in query) {
    urlQ += '&' + item + '=' + query[item];
  }
  urlQ = urlQ.replace(/\&/, '?');
  router.push(url + urlQ).catch(()=> {});
}
export function replaceRoute(url, query) {
  let urlQ = '';
  for (let item in query) {
    urlQ += '&' + item + '=' + query[item];
  }
  urlQ = urlQ.replace(/\&/, '?');
  router.replace(url + urlQ).catch(()=> {});
}