import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import "./pemission";
import packageJson from '../package.json';
Vue.prototype.$appVersion = packageJson.version;

import VuetifyDialog from './plugins/custom-dialog';
import './plugins/custom-dialog.css';

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});

import {LoadingPlugin} from "vuetify-loading-overlay"
Vue.use(LoadingPlugin, {
  spinnerProps: { color: '#008DD0', value: 100, indeterminate: true, width: 6, size: 60 },
  overlayProps: {},
});

// Fontawesome Icon
// npm i --save @fortawesome/fontawesome-svg-core @fortawesome/free-solid-svg-icons
// npm i --save @fortawesome/vue-fontawesome @fortawesome/free-regular-svg-icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas);
library.add(far);
Vue.component('font-awesome-icon', FontAwesomeIcon);
// icon ex) <font-awesome-icon :icon="['fas', 'times']" />

import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );

import networkController from "@/api/networkController";
Vue.prototype.networkController = networkController;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
