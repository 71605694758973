import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async checkNewNotification() {
    const url = `/notification/new`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getNotification(page) {
    const url = `/notification?page=${page}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getNotice(page) {
    const url = `/notification/notice?page=${page}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async addNotification(data) {
    const url = `/notification`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateNotification(data, notificationId) {
    const url = `/notification/read?notificationId=${notificationId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateReadNotice(data, notificationId) {
    const url = `/notification/read-notice?notificationId=${notificationId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateNotice(data, notificationId) {
    const url = `/notification/notice?notificationId=${notificationId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteNotice(notificationId) {
    const url = `/notification?notificationId=${notificationId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}