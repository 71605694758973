import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getWeather(baseDate, baseTime, numOfRows, lat, lon) {
    const url = `/weather?base_date=${baseDate}&base_time=${baseTime}&numOfRows=${numOfRows}&lat=${lat}&lon=${lon}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getWeatherFast(baseDate, baseTime, lat, lon) {
    const url = `/weather/weather-fast?base_date=${baseDate}&base_time=${baseTime}&lat=${lat}&lon=${lon}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getMidFcst(baseDate, baseTime, lat, lon) {
    const url = `/weather/getMidFcst?base_date=${baseDate}&base_time=${baseTime}&lat=${lat}&lon=${lon}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getMidLandFcst(baseDate, baseTime, lat, lon) {
    const url = `/weather/getMidLandFcst?base_date=${baseDate}&base_time=${baseTime}&lat=${lat}&lon=${lon}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getMidTa(baseDate, baseTime, lat, lon) {
    const url = `/weather/getMidTa?base_date=${baseDate}&base_time=${baseTime}&lat=${lat}&lon=${lon}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getMidWeather(baseDate, baseTime, lat, lon) {
    const url = `/weather/getMidWeather?base_date=${baseDate}&base_time=${baseTime}&lat=${lat}&lon=${lon}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getTMcoord(lat, lon) {
    const url = `/weather/transcoord?lat=${lat}&lon=${lon}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getNearbyMsrstnList(tmX, tmY) {
    const url = `/weather/dust/getNearbyMsrstnList?tmX=${tmX}&tmY=${tmY}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getMsrAirData(stationName, dataTerm) {
    const url = `/weather/dust/airInfo?stationName=${stationName}&dataTerm=${dataTerm}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}