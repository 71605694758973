import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async signUp(data) {
    const url = '/auth/signup';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
  async login(data) {
    const url = '/auth/login';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
  async tokenRefresh() {
    const url = '/auth/refresh';
    const response = await customAxios({
      url, method: 'GET', withCredentials: true
    })
    return Promise.resolve(response.data);
  },
  async emailVerification(data) {
    const url = '/auth/email-certificate';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
  async changePassword(data) {
    const url = '/auth/change-password';
    const response = await customAxios({
      url, method: 'POST', data
    })
    return Promise.resolve(response.data);
  },
}