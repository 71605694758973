import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getParkingData() {
    const url = '/parking';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async createParkingData(data) {
    const url = '/parking';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token, 'Content-Type': 'multipart/form-data'}
    })
    return Promise.resolve(response.data);
  },
  async updateParkingData(data) {
    const url = '/parking';
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token, 'Content-Type': 'multipart/form-data'}
    })
    return Promise.resolve(response.data);
  },
  async updateParkingDescription(data) {
    const url = '/parking/description';
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteParkingData(fileName) {
    const url = `/parking?fileName=${fileName}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}