import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getBoardCommentList(page, boardId) {
    const url = `/board-comment?page=${page}&boardId=${boardId}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getBoardCommentReplies(boardCommentId) {
    const url = `/board-comment/reply?boardCommentId=${boardCommentId}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async createBoardComment(data, boardId) {
    const url = `/board-comment/${boardId}`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async createBoardCommentReply(data, boardId, parentId) {
    const url = `/board-comment/reply/${boardId}/${parentId}`;
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateBoardComment(data, boardCommentId) {
    const url = `/board-comment?boardCommentId=${boardCommentId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteBoardComment(boardId, boardCommentId, replyCount) {
    const url = `/board-comment?boardId=${boardId}&boardCommentId=${boardCommentId}&replyCount=${replyCount}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteBoardCommentReply(boardId, boardCommentId, parentId) {
    const url = `/board-comment/reply?boardId=${boardId}&boardCommentId=${boardCommentId}&parentId=${parentId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}