import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import userData from './userData';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      // 여기에 쓴 모듈만 로컬에 저장
      paths: ['userData'],
    })
  ],
  state: { // this.$store.state.x 로 접근
    isMobile: false,
    isIOS: false,
    isTablet: false,
    cryptoKey: null,
		carEvent: null, // 차계부 이벤트
		carEventUpdate: false, // 차계부 이벤트 체크 여부
    selectedCodeTag: undefined, // 선택한 코드북 태그
    tempCodebookData: undefined, // 코드북 데이터 캐싱
    tempCodebookTag: undefined, // 코드북 태그 데이터 캐싱
    selectedBoardTag: undefined, // 선택한 게시판 태그
    tempBoardData: undefined, // 게시판 데이터 캐싱
    tempBoardTag: undefined, // 게시판 태그 데이터 캐싱
    recentLoginState: false, // 최근접속시간 업데이트 여부
    tokenRefreshStatus: false, // 토큰 갱신 여부
  },
  getters: {
		/*
		* this.$store.getters.getUmid
		* 형태로 호출
		*/
		getCarEvent: (state) => {
			return state.carEvent;
		},
    getCarEventUpdate: (state) => {
      return state.carEventUpdate;
    },
    getSelectedCodeTag: (state) => {
      return state.selectedCodeTag;
    },
    getTempCodebookData: (state) => {
      return state.tempCodebookData;
    },
    getTempCodebookTag: (state) => {
      return state.tempCodebookTag;
    },
    getSelectedBoardTag: (state) => {
      return state.selectedBoardTag;
    },
    getTempBoardData: (state) => {
      return state.tempBoardData;
    },
    getTempBoardTag: (state) => {
      return state.tempBoardTag;
    },
  },
  modules: {
    userData: userData,
  },
  mutations: {
    /*
		  this.$store.commit('setUser', data);
			이와 같은 형태로 호출
		*/
		setCarEvent(state, data) {
      state.carEvent = data;
    },
    setCarEventUpdate(state, data) {
      state.carEventUpdate = data;
    },
    setSelectedCodeTag(state, data) {
      state.selectedCodeTag = data;
    },
    setTempCodebookData(state, data) {
      state.tempCodebookData = data;
    },
    setTempCodebookTag(state, data) {
      state.tempCodebookTag = data;
    },
    setSelectedBoardTag(state, data) {
      state.selectedBoardTag = data;
    },
    setTempBoardData(state, data) {
      state.tempBoardData = data;
    },
    setTempBoardTag(state, data) {
      state.tempBoardTag = data;
    },
  },
  actions: {
  },
})
