import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async searchAddress(searchWord, page) {
    const url = `/map/search/address?searchWord=${searchWord}&page=${page}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async searchKeyword(searchWord, page, lat, lon, radius, sort) {
    const url = `/map/search/keyword?searchWord=${searchWord}&page=${page}&lat=${lat}&lon=${lon}&radius=${radius}&sort=${sort}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async coordToAddress(lat, lon) {
    const url = `/map/coord2address?lat=${lat}&lon=${lon}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}