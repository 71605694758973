import customAxios from '@/api/customAxios';
import Store from '@/store/index';

export default {
  async getMySchedule() {
    const url = '/schedule';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getCategorySchedule(category) {
    const url = `/schedule/category?category=${category}`;
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getTodaySchedule() {
    const url = '/schedule/today';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async getNextSchedule() {
    const url = '/schedule/next';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async createSchedule(data) {
    const url = '/schedule';
    const response = await customAxios({
      url, method: 'POST', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async updateSchedule(data, scheduleId) {
    const url = `/schedule?scheduleId=${scheduleId}`;
    const response = await customAxios({
      url, method: 'PUT', data, headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  async deleteSchedule(scheduleId) {
    const url = `/schedule?scheduleId=${scheduleId}`;
    const response = await customAxios({
      url, method: 'DELETE', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
}